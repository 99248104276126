import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Proposal } from '../proposal-list/proposal-list.component';
import API from '@/app/shared/service/client-api.service';

@Component({
  selector: 'app-proposal-show',
  templateUrl: './proposal-show.component.html',
  styleUrls: ['./proposal-show.component.scss']
})
export class ProposalShowComponent implements OnInit {
  PARCEIRO_SHOW = true
  EMITENTE_SHOW = true
  data: Proposal;
  constructor(
    private route: ActivatedRoute,
  ) {

  }
  async ngOnInit() {
    const response = await API.get(`/proposals/${this.route.snapshot.params.id}`);
    this.data = response?.data;
  }
  
  formatarDataBrasileira(dataISOString: string): string {
    const data = new Date(dataISOString);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
  
    return `${dia}/${mes}/${ano}`;
  }
//   0 - solteiro
// 1 - casado
// 2 - divorciado
// 3 - viuvo
// 4 - outros


}
