import { UtilService } from '@/app/core/services/util.service';
import { Component, Input, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountShowComponent } from '../../account/account-show/account-show.component';
import { PERMISSIONS } from '../../account/shared/account.service';

@Component({
  selector: 'app-parcel-show',
  templateUrl: './parcel-show.component.html',
  styleUrls: ['./parcel-show.component.scss']
})
export class ParcelShowComponent {
  @ViewChild('modalContentParcel') modalContent;

  closeResult = '';
  content: any = {};

  isOpened = false;

  constructor(
    protected readonly util: UtilService,
    protected modalService: NgbModal
  ) {}

  async openLg(data: string): Promise<void> {

    this.content = data;
    if (!this.isOpened) {
      this.modalService.open(this.modalContent, { size: 'lg' });
      this.isOpened = true;
    } else {
      this.isOpened = false
    }
  }
  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeMyModal (event) {
    event.target.parentElement.parentElement.parentElement.classList.remove('md-show')
  }

  openMyModal (event) {
    document.querySelector('#' + event).classList.add('md-show')
  }

  close(modal: any) {
    modal.close();
    this.isOpened = false;
  }

  getPermissionLabel(name: string): string {
    console.log(name)
    return this.util.getLabel(name, PERMISSIONS);
  }

}
