/**
 * Created by Wilton Oliveira Ferreira on 01/04/2023
 */

export enum Permissions {
  LISTAR_PERMISSAO = "LISTAR_PERMISSAO",
  BUSCAR_PERMISSAO = "BUSCAR_PERMISSAO",
  ATUALIZAR_PERMISSAO = "ATUALIZAR_PERMISSAO",
  CRIAR_PERMISSAO = "CRIAR_PERMISSAO",
  APAGAR_PERMISSAO = "APAGAR_PERMISSAO",
  APAGAR_PERFIL = "APAGAR_PERFIL",
  CRIAR_PERFIL = "CRIAR_PERFIL",
  ATUALIZAR_PERFIL = "ATUALIZAR_PERFIL",
  BUSCAR_PERFIL = "BUSCAR_PERFIL",
  LISTAR_PERFIL = "LISTAR_PERFIL",
  LISTAR_ARQUIVO_CNAB = "LISTAR_ARQUIVO_CNAB",
  BUSCAR_ARQUIVO_CNAB = "BUSCAR_ARQUIVO_CNAB",
  ATUALIZAR_ARQUIVO_CNAB = "ATUALIZAR_ARQUIVO_CNAB",
  CRIAR_ARQUIVO_CNAB = "CRIAR_ARQUIVO_CNAB",
  APAGAR_ARQUIVO_CNAB = "APAGAR_ARQUIVO_CNAB",
  APAGAR_FUNDO_INVESTIMENTO = "APAGAR_FUNDO_INVESTIMENTO",
  ATUALIZAR_FUNDO_INVESTIMENTO = "ATUALIZAR_FUNDO_INVESTIMENTO",
  CRIAR_FUNDO_INVESTIMENTO = "CRIAR_FUNDO_INVESTIMENTO",
  BUSCAR_FUNDO_INVESTIMENTO = "BUSCAR_FUNDO_INVESTIMENTO",
  LISTAR_FUNDO_INVESTIMENTO = "LISTAR_FUNDO_INVESTIMENTO",
  LISTAR_ORGAO = "LISTAR_ORGAO",
  BUSCAR_ORGAO = "BUSCAR_ORGAO",
  ATUALIZAR_ORGAO = "ATUALIZAR_ORGAO",
  CRIAR_ORGAO = "CRIAR_ORGAO",
  APAGAR_ORGAO = "APAGAR_ORGAO",
  APAGAR_PARCELA = "APAGAR_PARCELA",
  ATUALIZAR_PARCELA = "ATUALIZAR_PARCELA",
  CRIAR_PARCELA = "CRIAR_PARCELA",
  BUSCAR_PARCELA = "BUSCAR_PARCELA",
  LISTAR_PARCELA = "LISTAR_PARCELA",
  LISTAR_PROPOSTA_CONTRATO = "LISTAR_PROPOSTA_CONTRATO",
  LISTAR_NOTIFICACAO = "LISTAR_NOTIFICACAO",
  BUSCAR_PROPOSTA_CONTRATO = "BUSCAR_PROPOSTA_CONTRATO",
  ATUALIZAR_PROPOSTA_CONTRATO = "ATUALIZAR_PROPOSTA_CONTRATO",
  CRIAR_PROPOSTA_CONTRATO = "CRIAR_PROPOSTA_CONTRATO",
  APAGAR_PROPOSTA_CONTRATO = "APAGAR_PROPOSTA_CONTRATO",
  APAGAR_CONTA = "APAGAR_CONTA",
  LISTAR_CONTA = "LISTAR_CONTA",
  BUSCAR_CONTA = "BUSCAR_CONTA",
  ATUALIZAR_CONTA = "ATUALIZAR_CONTA",
  CRIAR_CONTA = "CRIAR_CONTA",
  LOGIN_SYS = "LOGIN_SYS",
  LISTAR_CONTRATO = "LISTAR_CONTRATO",
  LISTAR_INTEGRACAO = "LISTAR_INTEGRACAO",
}
