/**
 * Created by Wilton Oliveira Ferreira on 15/02/2023
 */



export const ACCOUNT_SERVICE = 'account'
export const LEARNING_SERVICE = 'learning'
export const LOGIN_SERVICE = 'login'
export const DASHBOARD_SERVICE = 'dashboard'
export const SETTING_SERVICE = 'setting'
export const ROLE_SERVICE = 'role'

export const RESET_SERVICE = 'reset-password'

export const CREATE_ACTION = 'create'
export const UPDATE_ACTION = 'update'
export const DELETE_ACTION = 'delete'


export const AUTH_TOKEN = 'sac-auth-token';
export const AUTH_USER_DATA = 'sac-auth-user-data';

export const GUEST_ROUTE = '/guest'
export const ADMIN_ROUTE = '/admin'

export const LOGIN_PAGE_ROUTE = `${GUEST_ROUTE}/${LOGIN_SERVICE}`;
export const RESET_PAGE_ROUTE = `${GUEST_ROUTE}/${RESET_SERVICE}`;
export const HOME_PAGE_ROUTE = `${ADMIN_ROUTE}/${DASHBOARD_SERVICE}/default`;

export const ACCOUNT_UPDATE_ROUTE = `${ADMIN_ROUTE}/${ACCOUNT_SERVICE}/${UPDATE_ACTION}`;
export const ROLE_UPDATE_ROUTE = `${ADMIN_ROUTE}/${SETTING_SERVICE}/${ROLE_SERVICE}/${UPDATE_ACTION}`;
export const ACCOUNT_LIST_ROUTE = `${ADMIN_ROUTE}/${ACCOUNT_SERVICE}`;
export const ROLE_LIST_ROUTE = `${ADMIN_ROUTE}/${SETTING_SERVICE}`;
export const LEARNING_LIST_ROUTE = `${ADMIN_ROUTE}/${LEARNING_SERVICE}`;


export const LIST_CONTRACTS = 'proposals'


export const LIMIT_DEFAULT = 10
export const OFFSET_DEFAULT = 0
