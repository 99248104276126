<div class="row" >
    <div class="col-sm-12">
      <div class="card table-card">
        <div class="card-header">
          <div class="d-flex align-items-center justify-content-between">
            <h5>Lista de Propostas</h5>
          </div>
        </div>
        <div class="card-body" [hideUnauthorized]="['LISTAR_CONTA']">
            <app-pg-table
            [dadosTabela]="dadosTabela"
            [rows]="rows"            
            [loading]="loading"
            [columns]="columns"
            (updatePage)="buscarPage($event)"
            [totalRecords]="totalRecords"
            [paginator]="true"
          >
          </app-pg-table>
        </div>
      </div>
    </div>
  </div>
  