import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParcelRoutingModule } from './parcel-routing.module';
import { ParcelListComponent } from './parcel-list/parcel-list.component';
import { SharedModule } from '@/app/shared/shared.module';
import { TableModule } from 'primeng/table';
import { ParcelShowComponent } from './parcel-show/parcel-show.component';



@NgModule({
  declarations: [
    ParcelListComponent,
    ParcelShowComponent
  ],
  imports: [
    CommonModule,
    ParcelRoutingModule,
    SharedModule,
    TableModule

  ]
})
export class ParcelModule { }
