// Angular import
import {Component, NgZone, OnInit} from '@angular/core';
import {BerryConfig} from "../../../app-config";
import {Location, LocationStrategy} from "@angular/common";

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {
  constructor() {

  }

  // Life cycle events
  ngOnInit(): void {}
}
