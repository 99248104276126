// Angular import
import { Pipe, PipeTransform } from '@angular/core';

// project import
import * as _ from 'lodash';

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {
  // public method
  transform(array: any[], query: string): any {
    if (query) {
      return _.filter(array, (row) => row.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
    }
    return array;
  }
}
