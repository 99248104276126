<div class="card table-card">
  <div class="card-header" (click)="PARCEIRO_SHOW = !PARCEIRO_SHOW">
    <div class="d-flex align-items-center justify-content-start">
      <i class="bi bi-chevron-down f-20 me-1" *ngIf="PARCEIRO_SHOW"></i>
      <i class="bi bi-chevron-up f-20 me-1" *ngIf="!PARCEIRO_SHOW"></i>
      <h5>PARCEIRO</h5>
    </div>
  </div>
  <div class="card-body" *ngIf="PARCEIRO_SHOW">
    <div class="row">
      <div class="col-3">
        <label><b>Razão Social</b></label>
        <p>ANREZ CONSULTORIA LTDA</p>
      </div>
      <div class="col-3">
        <label><b>CNPJ</b></label>
        <p>37.959.501/0001-00</p>
      </div>
      <div class="col-3">
        <label><b>Endereço</b></label>
        <p>AVENIDA ESTADOS UNIDOS,289 - 40010-020 - COMÉRCIO</p>
      </div>
      <div class="col-3">
        <label><b>Cidade/UF</b></label>
        <p>SALVADOR/ BA</p>
      </div>
    </div>
  </div>
</div>

<p-divider></p-divider>

<div class="card table-card">
  <div class="card-header" (click)="EMITENTE_SHOW = !EMITENTE_SHOW">
    <div class="d-flex align-items-center justify-content-start">
      <i class="bi bi-chevron-down f-20 me-1" *ngIf="EMITENTE_SHOW"></i>
      <i class="bi bi-chevron-up f-20 me-1" *ngIf="!EMITENTE_SHOW"></i>
      <h5>EMITENTE</h5>
    </div>
  </div>
  <div class="card-body" *ngIf="EMITENTE_SHOW">
    <div class="row">
      <div class="col-3">
        <label><b>Nome</b></label>
        <p> {{ data?.person?.name }} </p>
      </div>
      <div class="col-3">
        <label><b>Documento</b></label>
        <p> {{ data?.clientDocument }} </p>
      </div>
      <div class="col-2">
        <label><b>RG</b></label>
        <p> {{ data?.person.rg }} </p>
      </div>
      <div class="col-1">
        <label><b>Expedidor</b></label>
        <p> {{ data?.person.rgOrgan }} </p>
      </div>
      <div class="col-1">
        <label><b>UF</b></label>
        <p> {{ data?.person.rgUf }} </p>
      </div>
      <div class="col-2">
        <label><b>Emissão</b></label>
        <p> {{ data?.person?.rgIssuedAt }} </p>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <label><b>Endereço</b></label>
        <p> {{ data?.address.street }}, {{ data?.address.cep }} - {{ data?.address.neighborhood }} </p>
      </div>
      <div class="col-3">
        <label><b>Cidade/UF</b></label>
        <p> {{ data?.address.city }}/{{ data?.address.uf }} </p>
      </div>
      <div class="col-2">
        <label><b>Nacionalidade</b></label>
        <p> {{ data?.person.nationality }} </p>
      </div>
      <div class="col-2">
        <label><b>Local Nascimento</b></label>
        <p> {{ data?.person.naturanlness }}/{{ data?.person.naturanlnessUf }} </p>
      </div>
      <div class="col-2">
        <label><b>Estado Civil</b></label>
        <p> {{ data?.person.maritalStatus }} </p>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <label><b>Banco</b></label>
        <p> {{ data?.bankNumber }}</p>
      </div>
      <div class="col-3">
        <label><b>Conta</b></label>
        <p> {{ data?.bankAccount }}-{{ data?.bankAccountDigit }}</p>
      </div>
      <div class="col-2">
        <label><b> Agência </b></label>
        <p> {{ data?.bankAgency }}</p>
      </div>
      <div class="col-2">
        <label><b>Tipo</b></label>
        <p> {{ data?.bankAccountType }}</p>
      </div>
    </div>
  </div>
</div>

<div class="card table-card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <h5>DADOS DA PROPOSTA</h5>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-2">
        <label><b>Código da Proposta</b></label>
        <p> {{ data?.proposalCode }}</p>
      </div>
      <div class="col-2">
        <label><b>Data da Proposta</b></label>
        <p> {{ formatarDataBrasileira(data?.operationAt)}} </p>
      </div>
      <div class="col-2">
        <label><b> Tipo Operação </b></label>
        <p> {{ data?.operationCode }}</p>
      </div>
      <div class="col-2">
        <label><b>Data Emissão Prevista</b></label>
        <p>01/07/2023 </p>
      </div>
      <div class="col-2">
        <label><b>Data 1º Vencimento</b></label>
        <p> {{ formatarDataBrasileira(data?.firstInstalmentAt)}}</p>
      </div>
      <div class="col-2">
        <label><b>Data último Vencimento</b></label>
        <p> {{ formatarDataBrasileira(data?.lastInstalmentAt)}}</p>
      </div>
    </div>
  </div>
</div>

<p-divider></p-divider>

<div class="card table-card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <h5>ASSINATURAS REQUERIDAS</h5>
    </div>
  </div>
  <div class="card-body">
    <div class="row mb-3">
      <div class="col">
        <label><b>Código</b></label>
      </div>
      <div class="col">
        <label><b>Documento</b></label>
      </div>
      <div class="col">
        <label><b>Papel</b></label>
      </div>
      <div class="col">
        <label><b>Email</b></label>
      </div>
      <div class="col">
        <label><b>Celular</b></label>
      </div>
    </div>

    <div class="row" *ngFor="let signato of data?.signators">
      <div class="col">
        <label> {{ signato.code }} </label>
      </div>
      <div class="col">
        <label> {{ signato.document }} </label>
      </div>
      <div class="col">
        <label> SACADO </label>
      </div>
      <div class="col">
        <label> {{ signato.email }} </label>
      </div>
      <div class="col">
        <label> {{ signato.cellPhone }} </label>
      </div>
    </div>
  </div>
</div>

<p-divider></p-divider>

<div class="card table-card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <h5>EMISSÃO DA CÉDULA DE CRÉDITO BANCÁRIO ('CCB')</h5>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <a type="button"
          href="https://reportsteste.moneyp.com.br/imprimir?impressao=S&tipo=ccb&code={{data.code}}&integracao=ANREZ_CONSIGNADO&copias=1"
          class="btn btn-outline-primary me-3" style="width: 100%;" target="_blank"> Baixar CCB</a>
      </div>
      <div class="col">
        <a type="button"
          href="https://bmpteste.moneyp.com.br/processos/ccbdigital/assinatura.aspx?code={{data.code}}&empresa=467606dd-7e6e-40fc-8e4d-cba8b2b94226&integracao=ANREZ_CONSIGNADO"
          class="btn btn-outline-info" style="width: 100%;" target="_blank">Imprimir CBB</a>
      </div>
    </div>
  </div>
