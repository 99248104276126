import { Injectable } from '@angular/core';
import { Permissions } from "@/app/core/guards/permissions.guard";

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];

  roleGroup: string[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}
const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navegação',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'account',
        title: 'Contas',
        icon: 'ti ti-users',
        classes: 'nav-item',
        type: 'item',
        url: '/admin/account',
        roleGroup: [Permissions.LISTAR_CONTA]
      },
      // {
      //   id: 'parcel',
      //   title: 'Parcelas',
      //   icon: 'ti ti-table',
      //   classes: 'nav-item',
      //   type: 'item',
      //   url: '/admin/parcel',
      //   roleGroup: [Permissions.LISTAR_PARCELA]
      // },
      {
        id: 'contract',
        title: 'Contratos',
        icon: 'ti ti-file',
        classes: 'nav-item',
        type: 'item',
        url: '/admin/contract',
        roleGroup: [Permissions.LISTAR_CONTRATO]
      },
      {
        id: 'integration',
        title: 'Propostas',
        icon: 'ti ti-server',
        classes: 'nav-item',
        type: 'item',
        url: '/admin/proposal',
        roleGroup: [Permissions.BUSCAR_PROPOSTA_CONTRATO]
      },
      {
        id: 'notifications',
        title: 'Notificações',
        icon: 'ti ti-server',
        classes: 'nav-item',
        type: 'item',
        url: '/admin/notification',
        roleGroup: [Permissions.LISTAR_NOTIFICACAO]
      },
      {
        id: 'setting',
        title: 'Segurança',
        icon: 'ti ti-settings',
        type: 'collapse',
        roleGroup: [Permissions.LISTAR_PERFIL],
        children: [
          {
            id: 'integration',
            title: 'Perfil',
            type: 'item',
            external: true,
            url: '/admin/setting',
            roleGroup: [Permissions.LISTAR_INTEGRACAO]
          }
          // {
          //   id: 'integration',
          //   title: 'Permissão',
          //   type: 'item',
          //   external: true,
          //   url: '/admin/setting',
          //   roleGroup: [Permissions.LISTAR_INTEGRACAO]
          // },
          // {
          //   id: 'integration',
          //   title: 'Parâmetros',
          //   type: 'item',
          //   external: true,
          //   url: '/admin/setting',
          //   roleGroup: [Permissions.LISTAR_INTEGRACAO]
          // },

        ],
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
