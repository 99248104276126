<p-table #dt [value]="dadosTabela" [styleClass]="striped" [loading]="loading">
  <ng-template pTemplate="header">
    <tr [style]="{ 'font-size': fontHeader }">
      <ng-container *ngFor="let item of columns">
        <th ngClass="{{ item.align ? 'text-' + item.align.header : '' }}" [style]="item.style!" [pSortableColumn]="item.field"
          >{{ item.value }}<p-sortIcon *ngIf="sort" [field]="item.field"></p-sortIcon
        ></th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-data>
    <tr [style]="{ 'font-size': fontBody }">
      <ng-container *ngFor="let item of columns">
        <td
          *ngIf="item.type === 'text'"
          [style]="item.style"
          [style]="item.style"
          ngClass="{{ item.align ? 'text-' + (item.align.body ? item.align.body : item.align.header) : '' }}"
          >{{ rowData[item.field] }}{{ item.complement }}
        </td>

        <td
          *ngIf="item.type === 'money'"
          [style]="item.style"
          ngClass="{{ item.align ? 'text-' + (item.align.body ? item.align.body : item.align.header) : '' }}"
          >{{ rowData[item.field] | currency : item.currency : 'symbol' }}{{ item.complement }}
        </td>

        <td
          *ngIf="item.type === 'date-formatted'"
          [style]="item.style"
          ngClass="{{ item.align ? 'text-' + (item.align.body ? item.align.body : item.align.header) : '' }}"
          >{{ moment(rowData[item.field]).format(item.pattern ?? 'DD/MM/YYYY') }}{{ item.complement }}
        </td>

        <td
          *ngIf="item.type === 'text-object'"
          [style]="item.style"
          ngClass="{{ item.align ? 'text-' + (item.align.body ? item.align.body : item.align.header) : '' }}"
          >{{ rowData[item.field][item.field2] }}{{ item.complement }}
        </td>

        <td
          *ngIf="item.type === 'badge'"
          [style]="item.style"
          ngClass="{{ item.align ? 'text-' + (item.align.body ? item.align.body : item.align.header) : '' }}"
        >
          <ng-container *ngFor="let badge of item.badges">
            <span
              *ngIf="rowData[item.field] == badge.validation"
              class="{{ badge.bg == 'none' ? '' : 'badge bg-' + badge.bg + ' rounded f-12' }}"
              style="min-width: 90px"
              >{{ badge.name }}</span
            >
          </ng-container>
        </td>

        <td
          *ngIf="item.type === 'action'"
          [style]="item.style"
          ngClass="{{ item.align ? 'text-' + (item.align.body ? item.align.body : item.align.header) : '' }}"
        >
          <div>
            <ng-container *ngFor="let action of item.actions">
              <ng-container *ngIf="action.fieldConditionShow; else esseItem">
                <button
                  *ngIf="!rowData[action.fieldConditionShow]"
                  type="button"
                  class="btn btn-icon btn-outline-{{ action.bg }} d-inline-flex m-1"
                  (click)="execute(action.function, action.url, data)"
                >
                  <i class="ti {{ action.icon }}"></i>
                </button>
              </ng-container>
              <ng-template #esseItem>
                <button
                  type="button"
                  class="btn btn-icon btn-outline-{{ action.bg }} d-inline-flex m-1"
                  (click)="execute(action.function, action.url, data)"
                >
                  <i class="ti {{ action.icon }}"></i>
                </button>
              </ng-template>
            </ng-container>
          </div>
        </td>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary" *ngIf="paginator">
    <p-paginator
      (onPageChange)="buscarPage($event)"
      [first]="first"
      [rows]="rows"
      [totalRecords]="totalRecords"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} resultados"
    ></p-paginator>
  </ng-template>
</p-table>
