/**
 * Created by Wilton Oliveira Ferreira on 03/03/2023
 */
import API from '@/app/shared/service/client-api.service'
import { Component, OnInit } from '@angular/core'
import { FieldType } from '@ngx-formly/core'

@Component({
  selector: 'app-select-filter',
  template: `
    <div class="form-group mb-3">
      <label *ngIf="props.label">{{ props.label }}{{ props.required ? '*' : '' }}</label>
      <span class="p-fluid">
        <p-dropdown
          [style]="{ minHeight: '45px', height: '45px', borderRadius: '7px' }"
          [options]="items"
          [formlyAttributes]="field"
          [placeholder]="props.placeholder"
          [optionLabel]="props.labelProp"
          [optionValue]="props.valueProp"
          [filterBy]="props.labelProp"
          [showClear]="props.showClear ?? false"
          (onChange)="select($event)"
          [filter]="true"
        ></p-dropdown>
      </span>

    </div>
  `,
  styleUrls: ['./type.scss']
})
export class SelectFilterType extends FieldType implements OnInit {
  selected: any
  countries: any[]
  filtered: any[]
  search = ''
  items: any[] = []
  count = 100
  first = 0

  constructor () {
    super()
  }

  async ngOnInit (): Promise<void> {
    if (this.field?.props?.filter !== undefined && this.field.props.filter !== null) {
      const result = await API.get(this.field.props.filter)
      const selItem = {}
      selItem[this.field.props.valueProp] = ''
      selItem[this.field.props.labelProp] = 'Selecione um item'
      this.field.props.options = this.formatOptions(result.data)
      this.items = this.formatOptions(result.data)
    } else {
        this.items = this.field.props.options as any
    }
  }

  async select (event): Promise<void> {
    this.model[this.field.key.toString()] = event.value
    const value = event.value
  }

  formatOptions(value: any): any {
    if (this.props.formatOptions !== undefined) {
      console.log('Format:', this.props)
      return this.props?.formatOptions(value)
    } else {
      return value
    }
  }
}
