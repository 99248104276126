/**
 * Created by Wilton Oliveira Ferreira on 03/03/2023
 */
import API from '@/app/shared/service/client-api.service'
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FieldType } from '@ngx-formly/core'
import { PrimeNGConfig } from "primeng/api";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { TypeService } from "@/app/shared/types/type.service";

@Component({
  selector: 'app-mult-select',
  template: `
    <div class="form-group mb-3">
      <label class="mb-2" *ngIf="props.label">{{ props.label }}{{ props.required ? '*' : '' }}</label>
      <span class="p-fluid">
        <p-multiSelect
          [style]="{ minHeight: '44px', height: '44px', borderRadius: '8px' }"
          [options]="items"
          [formlyAttributes]="field"
          [placeholder]="props.placeholder"
          [optionLabel]="props.labelProp"
          [optionValue]="props.valueProp"
          [filterBy]="props.labelProp"
          [showClear]="props.showClear ?? false"
          (onChange)="select($event)"
          [filter]="true"
          [(ngModel)]="selectedItens"
          [selectedItemsLabel]="'{0} items selecionados'"
          display="chip"
        ></p-multiSelect>
      </span>
    </div>
  `,
  styleUrls: ['./type.scss']
})
export class MultSelectType  extends FieldType implements OnInit {
  selected: any
  countries: any[]
  filtered: any[]
  search = ''
  items: any[] = []
  selectedItens: any[] = []
  count = 100
  first = 0

  constructor (
    private primengConfig: PrimeNGConfig,
    private readonly changeDetector: ChangeDetectorRef,
    protected readonly typeService: TypeService
  ) {
    super()
  }

  async ngOnInit (): Promise<void> {
    this.typeService.typeMultSelectSubject.subscribe((value: Map<string, any>) => {
      if (value.has(this.key.toString())) {
        this.selectedItens = value.get(this.key.toString());
      }
    });
    this.primengConfig.ripple = true;
    if (this.field?.props?.filter !== undefined && this.field.props.filter !== null) {
      const result = await API.get(this.field.props.filter)
      const selItem = {}
      selItem[this.field.props.valueProp] = ''
      selItem[this.field.props.labelProp] = 'Selecione um item'
      this.field.props.options = this.formatOptions(result.data)
      this.items = this.formatOptions(result.data)
    } else {
      this.items = this.field.props.options as any
    }
    this.selectedItens = this.model[this.key.toString()];
    this.changeDetector.detectChanges();
  }

  async select (event): Promise<void> {
    this.model[this.field.key.toString()] = event.value
  }

  formatOptions(value: any): any {
    if (this.props.formatOptions !== undefined) {
      return this.props?.formatOptions(value)
    } else {
      return value
    }
  }
}
