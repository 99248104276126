import { Injectable } from '@angular/core'
import moment from 'moment'
import { format } from 'cnpj'
import { ptCurrency } from 'currency-br'

export interface Option {
  label: string
  value: string

  color?: string
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  dateFormat (date: Date): string {
    return moment(date).format('DD/MM/YYYY')
  }

  dateAndHourFormat (date: Date): string {
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
  }

  cnpjFormat(value: any): string {
    if (value !== undefined && value !== null) {
      return format(value)
    } else {
      return ''
    }
  }

  getLabel(value: any, collection: Option[] = []): string {
    let result = '';
    if (value !== undefined && value !== null && collection.length > 0) {
      for ( const v of collection) {
        if (v.value === value) {
          result = v.label;
        }
      }
    }
    return result;
  }

  currencyFormatter(value: number): string {
    let result = '';
    if (value !== undefined && value !== null) {
      result = ptCurrency(value);
    }
    return result;
  }
}
