/**
 * Created by Wilton Oliveira Ferreira on 28/03/2023
 */
import {ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {FieldType} from "@ngx-formly/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-mult-select',
  template: `
    <div class="form-group mb-3">
      <label class="mb-2" *ngIf="props.label">{{ props.label }}{{ props.required ? '*' : '' }}</label>
      <textarea class="form-control" [(ngModel)]="value" [id]="this.key"  [rows]="this.props.rows" [cols]="this.props.cols" (click)="open($event)" (change)="changeValue($event)">
      {{ this.model[this.key.toString()] }}
      </textarea>
    </div>
    <ng-template #modalContent let-modal>
      <div class="modal-header">
        <h5 class="modal-title h4" id="myLargeModalLabel">Selecione um Arquivo de Texto</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close(modal)"></button>
      </div>
      <div class="modal-body">
        <div class="row mb-5">
          <div class="col-12 col-md-12" >
            <input type="file" class="form-control"  (change)="onChange($event)">
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: ['./type.scss']
})
export class TextAreaType  extends FieldType implements OnInit {

  @ViewChild('modalContent') modalContent;

  fileContent: any = '';

  modal: any;

  value: any;

  constructor(
    private modalService: NgbModal,
    private readonly chagne: ChangeDetectorRef
  ) {
    super();
  }
  ngOnInit(): void {
  }

  public onChange(event: any): void {
    const fileList = event?.target?.files;
    let file = fileList[0];
    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = (x)  =>{
      self.fileContent = fileReader.result;
      this.value= self.fileContent?.toString()?.trim();
      this.chagne.detectChanges();
      this.close(this.modal);
    }
    fileReader.readAsText(file);
  }

  open(event: MouseEvent) {
    if (this.value === undefined || this.value === null || this.value?.toString()?.trim()?.length === 0 ) {
      this.modal = this.modalService.open(this.modalContent, {size: 'lg'});
    }
  }

  close(modal: any) {
    modal.close();
  }

  changeValue(event: Event) {
    this.chagne.detectChanges();
  }
}
