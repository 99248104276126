// Angular import
import { Component, OnInit } from '@angular/core';
import {Observable, of} from "rxjs";
import {UtilService} from "@/app/core/services/util.service";
import {PROFILE} from "@/app/pages/account/shared/account.service";
import {AuthenticationService} from "@/app/core/services/authentication.service";

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent implements OnInit {
  name: Observable<string> = new Observable<string>()
  profile: Observable<string> = new Observable<string>()
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly util: UtilService
  ) {}

  async ngOnInit(): Promise<void> {
    const data = await this.authenticationService.currentAuthenticatedUser()
    this.name = of(data.account.name)
    this.profile = of(this.util.getLabel(data.account.roles ?? 'admin', PROFILE))
  }

  async logout() {
    await this.authenticationService.signOut();
  }
}
