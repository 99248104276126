import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { AuthenticationService } from "@/app/core/services/authentication.service";

@Directive({
  selector: '[hideUnauthorized]'
})
export class HideUnauthorizedDirective implements OnInit {

  @Input('hideUnauthorized') roles: string[];

  constructor(
    private readonly el: ElementRef,
    private readonly authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    if (!this.authenticationService.hasRole(this.roles)) {
      try {
        this.el.nativeElement.style.display = 'none';
      } catch (e) {
        console.log(e);
      }
    }
  }

}
