/**
 * Created by Wilton Oliveira Ferreira on 17/02/2023
 */
import {Component, OnInit} from "@angular/core";
import {FieldType} from "@ngx-formly/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {FileUploadValidators} from "@iplab/ngx-file-upload";

@Component({
  selector: 'formly-repeat-section',
  template: `
    <form [formGroup]="demoForm">
      <file-upload formControlName="files" [(ngModel)]="uploadedFiles" (change)="selectedFile($event)" multiple="false">
        <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
          <span *ngIf="isFileDragDropAvailable"><i class="ti ti-upload">Enviar</i></span>
          <span *ngIf="!isFileDragDropAvailable">click</span>
        </ng-template>

        <ng-template let-i="index" let-file="file" let-control="control" #item>
          <div class="file-info" (click)="control.removeFile(file)">
            <span class="file-name">{{ file.name }}</span>
          </div>
        </ng-template>
      </file-upload>
    </form>
  `,
})
export class FileType extends FieldType implements OnInit {
  private filesControl = new UntypedFormControl(null, FileUploadValidators.filesLimit(2));
  public uploadedFiles: Array<File> = [];

  demoForm = new UntypedFormGroup({
    files: this.filesControl
  });

  // Constructor
  constructor() {
    super();
  }

  // Life cycle events
  ngOnInit() {}

  // private method
  toggleStatus() {
    this.filesControl.disabled ? this.filesControl.enable() : this.filesControl.disable();
  }

  async selectedFile(event: Event): Promise<void> {
    console.log('Event:', this.uploadedFiles);
    const file = await this.toBase64(this.uploadedFiles[0]);
    this.form.get(this.key.toString())?.patchValue(file);
    console.log('File:', file);
  }

  async toBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
