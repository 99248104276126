import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {LOGIN_PAGE_ROUTE} from "@/app/app.constant";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(protected router: Router, protected authenticationService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.isAuthenticated().pipe(
      tap(async (loggedIn) => {
        if (!loggedIn) {
          await this.router.navigateByUrl(LOGIN_PAGE_ROUTE);
        }
      })
    );
  }
}
