<ng-template #modalContentParcel let-modal>
    <div class="modal-header">
      <h5 class="modal-title h4" id="myLargeModalLabel">INFORMAÇÕES DA PARCELA</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close(modal)"></button>
    </div>
    <div class="modal-body">
      <div class="row mb-2">
        <div class="col-12 col-md-4" >
          <span><strong>Nome:&nbsp;</strong></span>
          <span>{{ content?.nome }}</span>
        </div>
        <div class="col-12 col-md-4" >
          <span><strong>CPF:&nbsp;</strong></span>
          <span>{{ content?.cpf }}</span>
        </div>
        <div class="col-12 col-md-4" >
          <span><strong>Matricula&nbsp;</strong></span>
          <span>{{ content.matricula }}</span>
        </div>
      </div>
  
      <div class="row mb-2">
        <div class="col-12 col-md-4" >
          <span><strong>ID Proposta:&nbsp;</strong></span>
          <span>{{ content?.idProposta }}</span>
        </div>
        <div class="col-12 col-md-4" >
          <span><strong>Nº Parcela:&nbsp;</strong></span>
          <span>{{ content?.numeroParcela }}</span>
        </div>
        <div class="col-12 col-md-4" >
          <span><strong>Vencimento:&nbsp;</strong></span>
          <span>{{ content?.vencimento }}</span>
        </div>
      </div>
  
      <div class="row mb-2">
        <div class="col-12 col-md-4" >
          <span><strong>Mora:&nbsp;</strong></span>
          <span>{{ content?.mora }}</span>
        </div>
        <div class="col-12 col-md-4" >
          <span><strong>Juros:&nbsp;</strong></span>
          <span>{{ content?.juro }}%</span>
        </div>
        <div class="col-12 col-md-4" >
          <span><strong>Multa:&nbsp;</strong></span>
          <span>{{content?.multa }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-4" >
          <span><strong>Valor:&nbsp;</strong></span>
          <span>{{ util.currencyFormatter(content?.valor) }}</span>
        </div>
        <div class="col-12 col-md-4" >
          <span><strong>Valor Pago:&nbsp;</strong></span>
          <span>{{ util.currencyFormatter(content?.valor_pago) }}</span>
        </div>
        <div class="col-12 col-md-4" >
          <span><strong>Status:&nbsp;</strong></span>
          <span class="badge bg-success rounded f-12">{{ content?.status }}</span>
        </div>
      </div>
     
    </div>
  </ng-template>
  
  