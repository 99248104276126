  <div class="row" >
    <div class="col-sm-12">
      <div class="card table-card">
        <div class="card-header">
          <div class="d-flex align-items-center justify-content-between">
            <h5>Lista de Parcelas</h5>
          </div>
        </div>
        <div class="card-body">
          <p-table #dt
              [value]="dadosTabela?.parcelas" styleClass="p-datatable-striped"
              [paginator]="true" [rows]="6" [showCurrentPageReport]="true"
              currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} resultados"
              responsiveLayout="stack" [tableStyle]="{ 'min-width': '60rem' }"
              >
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="idProposta">ID Proposta<p-sortIcon field="idProposta"></p-sortIcon></th>
                    <th pSortableColumn="nome">Nome<p-sortIcon field="nome"></p-sortIcon></th>
                    <th pSortableColumn="cpf">CPF<p-sortIcon field="cpf"></p-sortIcon></th>
                    <th pSortableColumn="matricula">Matricula<p-sortIcon field="matricula"></p-sortIcon></th>
                    <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
                    <th pSortableColumn="parcela">Parcela<p-sortIcon field="parcela"></p-sortIcon></th>
                    <th pSortableColumn="valor">Valor<p-sortIcon field="valor"></p-sortIcon></th>
                    <th pSortableColumn="valor_pago">Valor Pago<p-sortIcon field="valor_pago"></p-sortIcon></th>
                    <th pSortableColumn="vencimento">Vencimento<p-sortIcon field="vencimento"></p-sortIcon></th>
                    <th>Ação</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-parcel>
                <tr>
                    <td>{{ parcel.idProposta }}</td>
                    <td>{{ parcel.nome }}</td>
                    <td>{{ parcel.cpf }}</td>
                    <td>{{ parcel.matricula }}</td>
                    <td *ngIf="parcel.status == 'ABERTO' ">
                      <span  class="badge bg-success rounded f-12">{{parcel.status}}</span>
                    </td>
                    <td *ngIf="parcel.status !== 'ABERTO' ">
                      <span  class="badge bg-info rounded f-12">{{parcel.status}}</span>
                    </td>
                    <td>{{ parcel.numeroParcela }}</td>
                    <td>{{ parcel.valor }}</td>
                    <td>{{ parcel.valor_pago }}</td>
                    <td>{{ parcel.vencimento }}</td>

                    <td>
                      <div>
                        <button type="button" class="btn btn-icon btn-outline-primary d-inline-flex m-1" (click)="openModal(parcel)">
                          <i class="ti ti-eye"></i>
                        </button>
                      </div>
                    </td>
                </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

<app-parcel-show #show ></app-parcel-show>