import { ColumnsTable } from '@/app/shared/components/pg-table/pg-table.component';
import API from '@/app/shared/service/client-api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
interface Person {
  id: string;
  createdAt: string;
  updatedAt: string;
  enable: boolean;
  lastUpdatedByUser: null;
  name: string;
  rg: string;
  rgOrgan: string;
  rgUf: string;
  nationality: string;
  naturanlness: string;
  naturanlnessUf: string;
  maritalStatus: number;
  gender: string;
  email: string;
  phone: string;
  cellPhone: string;
  code: string;
  proposalCode: string;
  rgIssuedAt: string;
}

interface Address {
  id: string;
  createdAt: string;
  updatedAt: string;
  enable: boolean;
  lastUpdatedByUser: null;
  code: string;
  proposalCode: string;
  cep: string;
  street: string;
  neighborhood: string;
  city: string;
  uf: string;
  residesSince: null;
  isMainAddress: boolean;
  isCorrespondenceAddress: boolean;
}

interface Signator {
  id: string;
  createdAt: string;
  updatedAt: string;
  enable: boolean;
  cellPhone: boolean;
  email: boolean;
  lastUpdatedByUser: null;
  code: string;
  document: string;
  proposalCode: string;
}

export interface Proposal {
  id: string;
  createdAt: string;
  updatedAt: string;
  enable: boolean;
  lastUpdatedByUser: null;
  operationCode: string;
  clientDocument: string;
  code: string;
  operationAt: string;
  firstInstalmentAt: string;
  lastInstalmentAt: string;
  freeDayOfPayment: string;
  cet: string;
  proposalCode: string;
  deadline: number;
  instalmentValue: number;
  tacValue: null;
  iofValue: number;
  iofPercent: number;
  interestNegotiated: number;
  correspondentDocument: null;
  bankAccountType: number;
  bankAgency: string;
  bankAccount: string;
  bankAccountDigit: string;
  bankNumber: string;
  requestedValue: number;
  person: Person;
  address: Address;
  signators: Signator[];
}

@Component({
  selector: 'app-proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrls: ['./proposal-list.component.scss']
})
export class ProposalListComponent implements OnInit {

  dadosTabela: Proposal[]
  first: number = 0
  rows: number = 10
  totalRecords: number = 0
  loading: boolean = true

  columns: ColumnsTable[] = [
    {type: 'text', value: '#ID', field: 'proposalCode' },
    {type: 'date-formatted', value: 'Data', field: 'createdAt' },
    {type: 'text-object', value: 'Cliente', field: 'person', field2: 'name' },
    {type: 'text', value: 'Documento', field: 'clientDocument' },
    {type: 'money', value: 'Valor', field: 'requestedValue', currency: 'BRL' },
    {type: 'text', value: 'Parcela', field: 'deadline' },
    {type: 'badge', value: 'Situação', field: 'enable', badges: [
      {bg: 'success', name: 'Aprovado', validation: true },
      {bg: 'danger', name: 'Reprovado', validation: false }
    ]},

    {type: 'action', value: 'Ações', actions: [
      {bg: 'info', icon: 'ti-eye', url: 'admin/proposal/show'},
    ] }
  ]


  constructor(
    private router: Router
  ) {}

  async ngOnInit() {
    const response = await API.get(`/proposals?offset=0&limit=10`);
    this.dadosTabela = response?.data?.data;
    this.totalRecords = response.data.count
    this.loading = false
  }

  async buscarPage(event) {
    this.loading = true
    const response = await API.get(`/proposals?offset=${event.first}&limit=${event.rows}`);
     this.dadosTabela = response?.data?.data;
     this.loading = false
  }

  redirectToPage(data){
    this.router.navigate([`show/${data.id}`])
  }
}
