import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProposalListComponent } from './proposal-list/proposal-list.component';
import { ProposalShowComponent } from './proposal-show/proposal-show.component';

const routes: Routes = [
  {
    path: '',
    component: ProposalListComponent
  },
  {
    path: 'show/:id',
    component: ProposalShowComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProposalRoutingModule { }
