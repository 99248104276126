export class BerryConfig {
  static layout: string = 'vertical'; // vertical, horizontal, compact
  static isCollapse_menu: boolean = false;
  static isRtl: boolean = false; // true
  static isDarkLayout: boolean = false; // true
  static bodyColor: string = 'preset-7'; // preset-1, preset-2, preset-3, preset-4, preset-5, preset-6, preset-7
  static isBoxLayout: boolean = false; // true
  static headerBackColor: string = 'header-default'; // header-default
  static navBackColor: string = 'navbar-default'; // navbar-default
  static fontFamily: string = 'Roboto'; // Roboto, Poppins, Inter
}
