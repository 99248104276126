<ng-container *ngIf="!item.hidden">
  <!-- Horizontal Layout -->
  <li
    *ngIf="themeLayout === 'horizontal'"
    (mouseenter)="navCollapse($event)"
    data-username="Berry dashboard"
    class="nav-item coded-hasmenu"
    [routerLinkActive]="['active']"
  >
    <a [routerLinkActive]="['active']" href="javascript:" class="nav-link">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
    <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
  </li>

  <!-- Vertical Layout -->
  <li *ngIf="themeLayout === 'vertical'" data-username="Berry dashboard" class="nav-item coded-hasmenu" [routerLinkActive]="['active']">
    <a [routerLinkActive]="['active']" href="javascript:" class="nav-link" (click)="navCollapse($event)">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
    <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
  </li>

  <!-- Compact Layout -->
  <li
    *ngIf="themeLayout === 'compact'"
    data-username="Berry dashboard"
    class="nav-item coded-hasmenu"
    [routerLinkActive]="['active']"
    [placement]="'top'"
    ngbTooltip="{{ item.title }}"
    (click)="subMenuCollapse(subMenuContent)"
  >
    <a href="javascript:" class="nav-link">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
    <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
  </li>
  <ng-template #itemContent>
    <span class="coded-micon" *ngIf="item.icon">
      <i class="{{ item.icon }}"></i>
    </span>
    <span class="coded-mtext"
      >{{ item.title }}
      <span *ngIf="item.badge && themeLayout === 'horizontal'" class="badge label" [ngClass]="item.badge.type!">
        {{ item.badge.title }}
      </span>
    </span>
    <span *ngIf="item.badge && themeLayout === 'vertical'" class="coded-badge label" [ngClass]="item.badge.type!">
      {{ item.badge.title }}
    </span>
    <span *ngIf="item.badge && themeLayout === 'compact'" class="coded-badge label" [ngClass]="item.badge.type!">
      {{ item.badge.title }}
    </span>
  </ng-template>
  <ng-template #subMenuContent>
    <ul class="coded-submenu" [routerLinkActive]="['active']">
      <ng-container *ngFor="let item of item.children">
        <app-nav-group *ngIf="item.type == 'group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type == 'collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </ng-template>
</ng-container>
