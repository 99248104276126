// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// project import
import { BreadcrumbModule, CardModule, ModalModule } from './components';
import { DataFilterPipe } from './filter/data-filter.pipe';
import { TodoListRemoveDirective } from './directive/todo-list-remove.directive';
import { TodoCardCompleteDirective } from './directive/todo-card-complete.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DataTablesModule } from 'angular-datatables';
import { PickListModule } from 'primeng/picklist';

// bootstrap import
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
  NgbModule,
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDatepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormlyModule } from "@ngx-formly/core";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { RepeatTypeComponent } from "@/app/shared/types/repeat-section.type";
import { FileType } from "@/app/shared/types/file.type";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { SnotifyModule } from "ng-snotify";
import { HideUnauthorizedDirective } from "@/app/shared/directive/hide-unauthorized.directive";
import { DisableUnauthorizedDirective } from "@/app/shared/directive/disable-unauthorized.directive";
import { SelectFilterType } from "@/app/shared/types/select-filter.type";
import { MultSelectType } from "@/app/shared/types/mult-select.type";
import { MultiSelectModule } from "primeng/multiselect";
import { DropdownModule } from "primeng/dropdown";
import { TextAreaType } from "@/app/shared/types/text-area.type";
import { PgFilterComponent } from './components/pg-filter/pg-filter.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { PickListTypeComponent } from './components/pick-list-type/pick-list-type.component';
import { PgTableModule } from './components/pg-table/pg-table.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbModule,
    PickListModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgScrollbarModule,
    NgApexchartsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'file', component: FileType },
        { name: 'select-filter', component: SelectFilterType },
        { name: 'mult-select', component: MultSelectType },
        { name: 'custom-textarea', component: TextAreaType },
        { name: 'pickList', component: PickListTypeComponent },
      ],
      validationMessages: [
        { name: 'required', message: 'Este campo é obrigatório!' }
      ],
    }),
    FormlyBootstrapModule,
    DataTablesModule,
    FileUploadModule,
    DropdownModule,
    MultiSelectModule,
    SnotifyModule,
    TableModule,
    PaginatorModule,
    PgTableModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    DisableUnauthorizedDirective,
    HideUnauthorizedDirective,
    SpinnerComponent,
    NgbModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgScrollbarModule,
    NgApexchartsModule,
    FormlyModule,
    FormlyBootstrapModule,
    DataTablesModule,
    FileUploadModule,
    DropdownModule,
    MultiSelectModule,
    SnotifyModule,
    SelectFilterType,
    MultSelectType,
    TextAreaType,
    PgFilterComponent,
    TableModule,
    PaginatorModule,
    PgTableModule,
  ],
  declarations: [
    PickListTypeComponent,
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    DisableUnauthorizedDirective,
    HideUnauthorizedDirective,
    SpinnerComponent,
    RepeatTypeComponent,
    FileType,
    SelectFilterType,
    MultSelectType,
    TextAreaType,
    PgFilterComponent,
  ]
})
export class SharedModule { }
