<app-navigation
  class="coded-navbar pc-sidebar"
  [ngClass]="{
    'navbar-collapsed': navCollapsed,
    'theme-horizontal': this.config['layout'] === 'horizontal',
    'theme-compact': this.config['layout'] === 'compact',
    'mob-open': navCollapsedMob,
    'coded-trigger': submenuCollapse
  }"
  (onNavCollapsedMob)="navMobClick()"
  (onSubmenuCollapse)="this.submenuCollapse = !this.submenuCollapse"
></app-navigation>
<app-nav-bar (onNavCollapse)="this.navCollapsed = !this.navCollapsed" (onNavCollapsedMob)="navMobClick()"></app-nav-bar>
<div class="pc-container">
  <div class="coded-wrapper">
    <div class="coded-content">
      <div class="coded-inner-content">
        <app-breadcrumb></app-breadcrumb>
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="pc-footer">
  <div class="footer-wrapper container-fluid">
    <div class="row">
      <div class="col my-1">
        <p class="m-0"> Copyright &copy; <a href="javascript:">SAC</a> </p>
      </div>
      <div class="col-auto my-1">
        <ul class="list-inline footer-link mb-0">
          <li class="list-inline-item"><a href="javascript:">Home</a></li>
          <li class="list-inline-item">
            <a href="javascript:">Privacy Policy</a>
          </li>
          <li class="list-inline-item"><a href="javascript:">Contact us</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<app-configuration></app-configuration>
