import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalListComponent } from './proposal-list/proposal-list.component';
import { ProposalRoutingModule } from './proposal-routing.module';
import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ProposalShowComponent } from './proposal-show/proposal-show.component';
import { SharedModule } from '@/app/shared/shared.module';

@NgModule({
  declarations: [
    ProposalListComponent,
    ProposalShowComponent
  ],
  imports: [
    CommonModule,
    AccordionModule,
    ProposalRoutingModule,
    DividerModule,
    PanelModule,
    SharedModule
  ],
  exports: [
    ProposalListComponent
  ]
})
export class ProposalModule { }
