import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PgTableComponent } from './pg-table.component';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [ 
    CommonModule,
    TableModule,
    PaginatorModule
  ],
  declarations: [ PgTableComponent ],
  exports: [ PgTableComponent, TableModule, PaginatorModule]
})
export class PgTableModule { }
