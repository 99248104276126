import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpStatusCode } from "@angular/common/http";
import { AUTH_TOKEN, AUTH_USER_DATA, LOGIN_PAGE_ROUTE, LOGIN_SERVICE } from "@/app/app.constant";
import API from '@/app/shared/service/client-api.service';



export interface Account {
  id: string;
  email: string;
  name: string;
  enable: boolean;
  permissions: string[];
  investmentFunds: any[];
  roles: any[];
  createdAt: string;
  updatedAt: string;
}
export interface AuthCredential {
  account: Account;
  token: string;
  refreshToken: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentAutorizationSubject: BehaviorSubject<AuthCredential>;
  public currentAutorization: Observable<AuthCredential>;
  public loggedIn: BehaviorSubject<boolean>;
  public fullName: BehaviorSubject<string>;
  public loggedUser: BehaviorSubject<Account>;
  public profile: string;
  public token: BehaviorSubject<string>;

  constructor(private router: Router) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
    this.fullName = new BehaviorSubject<string>('');
    this.token = new BehaviorSubject<string>('');
    this.loggedUser = new BehaviorSubject<Account>(JSON.parse(localStorage.getItem(AUTH_USER_DATA)) as Account);
    this.currentAutorizationSubject = new BehaviorSubject<AuthCredential>(
      JSON.parse(JSON.stringify(
        {
          account: JSON.parse(localStorage.getItem(AUTH_USER_DATA)) as Account,
          token: localStorage.getItem(AUTH_TOKEN),
          refreshToken: localStorage.getItem(AUTH_TOKEN)
        }
      ))
    );

    this.currentAutorization = this.currentAutorizationSubject.asObservable();

  }


  public get currentAutorizationValue(): Account {
    return this.loggedUser.value
  }
  /** signin */
  async signIn(email, password): Promise<AuthCredential> {
    try {
      const result = await API.post(LOGIN_SERVICE, { email, password });
      if (result.status === HttpStatusCode.Accepted) {
        const auth = {
          account: {
            ...result.data.account,
            roles: result.data.roles
          },
          token: result.data.token,
          refreshToken: result.data.refreshToken
        }

        localStorage.setItem(AUTH_TOKEN, result.data.token);
        localStorage.setItem(AUTH_USER_DATA, JSON.stringify(auth.account));

        this.currentAutorizationSubject.next(auth);
        return auth;
      } else {
        throw new Error()
      }
    } catch (e) {
      console.error('Error:', e.message);
      throw e
    }

  }

  /** get authenticat state */
  public isAuthenticated(): Observable<boolean> {
    const result = localStorage.getItem(AUTH_TOKEN);
    const autenticated = result !== null;
    this.loggedIn.next(autenticated);
    return of(autenticated);

  }

  /** signout */
  async signOut(): Promise<void> {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_USER_DATA);
    this.loggedIn.next(false);
    await this.router.navigateByUrl(LOGIN_PAGE_ROUTE);
  }


  public async currentAuthenticatedUser(): Promise<AuthCredential> {
    return {
      account: JSON.parse(localStorage.getItem(AUTH_USER_DATA)) as Account,
      token: localStorage.getItem(AUTH_TOKEN),
      refreshToken: localStorage.getItem(AUTH_TOKEN)
    } as AuthCredential
  }

  hasRole(roles: string[]) {
    if (this.currentAutorizationValue?.roles !== undefined && this.currentAutorizationValue?.roles !== null && roles?.find((role) => this.currentAutorizationValue?.roles?.includes(role))?.length > 0) {
      return true;
    }
    return false;
  }

  async initializePermissions(): Promise<unknown> {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
