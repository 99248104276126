import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

export const PROFILE = [
  {
    label: 'Administrador',
    value: 'admin'
  },
  {
    label: 'Gestor',
    value: 'manager'
  },
  {
    label: 'Usuário',
    value: 'user'
  }
]

export const FUNDS = [
  {
    label: 'Fundo 1',
    value: 'fundo1'
  },
  {
    label: 'Fundo 2',
    value: 'fundo2'
  },
  {
    label: 'Fundo 3',
    value: 'fundo3'
  },
]

export const STATUS = [
  {
    label: 'Habilitado',
    value: true
  },
  {
    label: 'Desabilitado',
    value: false
  }
]

export const PERMISSIONS = [
  {
    label: 'Login no Applicativo',
    value: 'LOGIN_SYS'
  },
  {
    label: 'Atender Paciêntes',
    value: 'ATENDIMENTO_SYS'
  },
  {
    label: 'Dashboard Padrão',
    value: 'DASHBOARD_DEFAULT_SYS'
  },
  {
    label: 'Dashboard Analítica',
    value: 'DASHBOARD_ANALITICS_SYS'
  },
  {
    label: 'Criar Conta',
    value: 'CREATE_ACCOUNT'
  },
  {
    label: 'Atualizar Conta',
    value: 'UPDATE_ACCOUNT'
  },
  {
    label: 'Buscar Conta',
    value: 'FIND_ACCOUNT'
  },
  {
    label: 'Listar Conta',
    value: 'LIST_ACCOUNT'
  },
  {
    label: 'Apagar Conta',
    value: 'DELETE_ACCOUNT'
  },
  {
    label: 'Criar Médico',
    value: 'CREATE_DOCTOR'
  },
  {
    label: 'Atualizar Médico',
    value: 'UPDATE_DOCTOR'
  },
  {
    label: 'Buscar Médico',
    value: 'FIND_DOCTOR'
  },
  {
    label: 'Listar Médico',
    value: 'LIST_DOCTOR'
  },
  {
    label: 'Apagar Médico',
    value: 'DELETE_DOCTOR'
  },
  {
    label: 'Criar Paciente',
    value: 'CREATE_PATIENT'
  },
  {
    label: 'Atualizar Paciente',
    value: 'UPDATE_PATIENT'
  },
  {
    label: 'Buscar Paciente',
    value: 'FIND_PATIENT'
  },
  {
    label: 'Listar Paciente',
    value: 'LIST_PATIENT'
  },
  {
    label: 'Apagar Paciente',
    value: 'DELETE_PATIENT'
  },
  {
    label: 'Criar Clinica',
    value: 'CREATE_COMPANY'
  },
  {
    label: 'Atualizar Clinica',
    value: 'UPDATE_COMPANY'
  },
  {
    label: 'Buscar Clinica',
    value: 'FIND_COMPANY'
  },
  {
    label: 'Listar Clinica',
    value: 'LIST_COMPANY'
  },
  {
    label: 'Apagar Clinica',
    value: 'DELETE_COMPANY'
  },
  {
    label: 'Criar Notificação',
    value: 'CREATE_NOTIFICATION'
  },
  {
    label: 'Atualizar Notificação',
    value: 'UPDATE_NOTIFICATION'
  },
  {
    label: 'Buscar Notificação',
    value: 'FIND_NOTIFICATION'
  },
  {
    label: 'Listar Notificação',
    value: 'LIST_NOTIFICATION'
  }, {
    label: 'Apagar Notificação',
    value: 'DELETE_NOTIFICATION'
  }
]

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public showModal = new Subject<string>();

  constructor() {
  }
}
