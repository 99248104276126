<div class="ms-auto">
  <ul class="list-unstyled">
    <li appToggleFullScreen class="pc-h-item">
      <a href="javascript:" class="full-screen pc-head-link head-link-secondary me-0 mobile-menu"><i class="feather icon-maximize"></i></a>
    </li>
    <li>
      <div class="dropdown pc-h-item" ngbDropdown>
        <div class="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
          <div class="dropdown-header">
            <a href="javascript:!" class="link-primary float-end text-decoration-underline">Mark as all read</a>
            <h5>
              All Notification
              <span class="badge bg-warning rounded-pill ms-1">01</span>
            </h5>
          </div>
          <ng-scrollbar style="height: calc(100vh - 215px)" visibility="hover">
            <div class="dropdown-header px-0 text-wrap">
              <div class="list-group list-group-flush w-100">
                <div class="list-group-item">
                  <select class="form-select">
                    <option value="all">All Notification</option>
                    <option value="new">New</option>
                    <option value="unread">Unread</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img src="assets/images/user/avatar-2.jpg" alt="user-image" class="user-avtar" />
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">2 min ago</span>
                      <h5>John Doe</h5>
                      <p class="text-body fs-6">It is a long established fact that a reader will be distracted </p>
                      <div class="badge rounded-pill bg-light-danger">Unread</div>
                      <div class="badge rounded-pill bg-light-warning">New</div>
                    </div>
                  </div>
                </a>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <div class="user-avtar bg-light-success"><i class="ti ti-building-store"></i></div>
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">3 min ago</span>
                      <h5>Store Verification Done</h5>
                      <p class="text-body fs-6">We have successfully received your request.</p>
                      <div class="badge rounded-pill bg-light-danger">Unread</div>
                    </div>
                  </div>
                </a>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <div class="user-avtar bg-light-primary"><i class="ti ti-mailbox"></i></div>
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">5 min ago</span>
                      <h5>Check Your Mail.</h5>
                      <p class="text-body fs-6">All done! Now check your inbox as you're in for a sweet treat! </p>
                      <button class="btn btn-sm btn-primary">Mail <i class="ti ti-brand-telegram"></i></button>
                    </div>
                  </div>
                </a>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img src="assets/images/user/avatar-1.jpg" alt="user-image" class="user-avtar" />
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">8 min ago</span>
                      <h5>John Doe</h5>
                      <p class="text-body fs-6">Uploaded two file on &nbsp;<strong>21 Jan 2020</strong></p>
                      <div class="notification-file d-flex p-3 bg-light-secondary rounded">
                        <i class="ti ti-arrow-bar-to-down"></i>
                        <h5 class="m-0">demo.jpg</h5>
                      </div>
                    </div>
                  </div>
                </a>
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img src="assets/images/user/avatar-3.jpg" alt="user-image" class="user-avtar" />
                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">10 min ago</span>
                      <h5>Joseph William</h5>
                      <p class="text-body fs-6">It is a long established fact that a reader will be distracted </p>
                      <div class="badge rounded-pill bg-light-success">Confirmation of Account</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-scrollbar>
          <div class="dropdown-divider"></div>
          <div class="text-center py-2">
            <a href="javascript:!" class="link-primary">Mark as all read</a>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="dropdown pc-h-item header-user-profile" ngbDropdown>
        <a
          class="pc-head-link head-link-primary dropdown-toggle arrow-none me-0"
          data-bs-toggle="dropdown"
          href="javascript:"
          ngbDropdownToggle
        >
          <img src="assets/images/user/avatar-2.jpg" alt="user-image" class="user-avtar" />
          <span>
            <i class="ti ti-settings"></i>
          </span>
        </a>
        <div class="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
          <div class="dropdown-header">
            <ng-scrollbar style="height: 150px" visibility="hover">
              <a class="dropdown-item">
                <i class="ti ti-user"></i>
                <span> {{ name | async }}&nbsp;({{profile | async}})</span>
              </a>
              <a [routerLink]="['/admin/account/update-me']" class="dropdown-item">
                <i class="ti ti-user"></i>
                <span>Dados pessoais</span>
              </a>
              <a (click)="logout()" [routerLink]="['/guest/login']" class="dropdown-item">
                <i class="ti ti-logout"></i>
                <span>Sair</span>
              </a>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
