<ng-container *ngIf="!item.hidden">
  <li [hideUnauthorized]="item.roleGroup" [ngClass]="item.classes!" *ngIf="item.url && !item.external && themeLayout === 'vertical'" [routerLinkActive]="['active']">
    <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="coded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
      <ng-template #directTitle>
        {{ item.title }}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>
  <li [ngClass]="item.classes!" *ngIf="item.url && !item.external && themeLayout === 'horizontal'" [routerLinkActive]="['active']">
    <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="coded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}b</span>
      <ng-template #directTitle>
        {{ item.title }}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>
  <li
    [ngClass]="item.classes!"
    *ngIf="item.url && !item.external && themeLayout === 'compact'"
    [routerLinkActive]="['active']"
    [placement]="'top'"
    ngbTooltip="{{ item.title }}"
  >
    <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="coded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
      <ng-template #directTitle>
        {{ item.title }}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>
  <li [ngClass]="item.classes!" *ngIf="item.url && item.external">
    <a [target]="item.target ? '_blank' : '_self'" [href]="item.url">
      <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
      <span class="coded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
      <ng-template #directTitle>
        {{ item.title }}
      </ng-template>
      <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
    </a>
  </li>
  <ng-template #itemIcon>
    <span *ngIf="item.icon" class="coded-micon"><i class="feather" [ngClass]="item.icon"></i></span>
  </ng-template>
  <ng-template #itemBadge>
    <span *ngIf="item.badge && themeLayout === 'vertical'" class="coded-badge label" [ngClass]="item.badge.type!">
      {{ item.badge.title }}
    </span>
    <span *ngIf="item.badge && themeLayout === 'horizontal'" class="badge label" [ngClass]="item.badge.type!">
      {{ item.badge.title }}
    </span>
    <span *ngIf="item.badge && themeLayout === 'compact'" class="coded-badge label" [ngClass]="item.badge.type!">
      {{ item.badge.title }}
    </span>
  </ng-template>
</ng-container>
