<!-- [ Sidebar Menu ] start -->
<nav class="">
  <div class="navbar-wrapper">
    <div class="m-header">
      <!-- [ Header Topbar ] start -->
      <a [routerLink]="['/default/']" class="b-brand">
        <!-- ========   change your logo hear   ============ -->
        <img src="assets/images/logo-dark.svg" alt="" class="logo logo-lg" />
      </a>
    </div>
    <app-nav-content
      (onNavCollapsedMob)="navCollapseMob()"
      (onSubmenuCollapse)="navSubmenuCollapse()"
      class="scroll-div w-100 compact"
    ></app-nav-content>
  </div>
</nav>
<!-- [ Sidebar Menu ] end -->
