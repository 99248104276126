import { ACCOUNT_UPDATE_ROUTE } from '@/app/app.constant';
import API from '@/app/shared/service/client-api.service';
import { SweetAlertService } from '@/app/shared/service/sweet-alert.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { ParcelService } from '../shared/parcel.service';
import { ParcelShowComponent } from '../parcel-show/parcel-show.component';

@Component({
  selector: 'app-parcel-list',
  templateUrl: './parcel-list.component.html',
  styleUrls: ['./parcel-list.component.scss']
})
export class ParcelListComponent  implements OnInit  {

  dadosTabela: any

  @ViewChild('show') show: ParcelShowComponent;

  constructor(
    private readonly parcelService: ParcelService
  ) {
  }

  ngOnInit(): void {

    const response  = this.parcelService.getParcels();
    this.dadosTabela = response;
    console.log(this.dadosTabela);
    

  }

  openModal(id){
    this.show.openLg(id);
  }








  // dtExportButtonOptions: any = {};
  // @ViewChild('show') show;

  // constructor(
  //   private readonly router: Router,
  //   private readonly sweetAlertService: SweetAlertService,
    
  // ) {
  // }

  // ngOnInit(): void {
  //   this.dtExportButtonOptions = {
  //     processing: true,
  //     serverSide: true,
  //     stateSave: true,
  //     searching: false,
  //     ajax: async (data: any, callBack: any) => {
  //       // const response = await API.get( `/parcels?limit=${data.length}&offset=${data.start}`);
  //       const response  = await this.parcelService.getParcels();


  //       const result = response;
  //       console.log('Result:', result)
  //       await callBack(
  //         {
  //           draw: data.draw,
  //           recordsTotal: result.total,
  //           recordsFiltered: result.pagina,
  //           data: result.parcelas
  //         }
  //       );
  //     },
  //     language: {
  //       url: 'i18n/pt-BR.json'
  //     },
  //     columns: [
  //       {
  //         title: 'ID Proposta',
  //         data: 'idProposta'
  //       },
  //       {
  //         title: 'Nome',
  //         data: 'nome'
  //       },
  //       {
  //         title: 'CPF',
  //         data: 'cpf'
  //       },
  //       {
  //         title: 'Status',
  //         data: 'status'
  //       },
  //       {
  //         title: 'Status',
  //         data: 'status'
  //       },
  //       {
  //         title: 'Valor',
  //         data: 'valor'
  //       },
  //       {
  //         title: 'Valor pago',
  //         data: 'valor_pago'
  //       },
  //       {
  //         title: 'Juros',
  //         data: 'juro'
  //       },
  //       {
  //         title: 'Mora',
  //         data: 'mora'
  //       },
  //       {
  //         title: 'Multa',
  //         data: 'multa'
  //       },
  //     ]
  //   };


  // }
  // configureTg(e: any) {
  //   console.log(e)
  // }
}
