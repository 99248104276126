import { LIMIT_DEFAULT, OFFSET_DEFAULT } from '@/app/app.constant';
import { Component, Input, Output, EventEmitter } from '@angular/core';


export interface filtroParams {
  limit?: number,
  offset?: number,
  field?: string,
  search?: string
}


@Component({
  selector: 'app-pg-filter',
  templateUrl: './pg-filter.component.html',
  styleUrls: ['./pg-filter.component.scss']
})
export class PgFilterComponent {

  @Input() field: string
  @Input() matchMode: string
  @Input() showMenu: boolean
  
  @Output() Filtro = new EventEmitter<filtroParams>()

  filtro(event){
    const body: filtroParams = {
      limit: LIMIT_DEFAULT,
      offset: OFFSET_DEFAULT,
      field: this.field,
      search: event.target.value
    }
    this.Filtro.emit(body)
  }
}
