import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { AuthGuard } from "./core/guards/auth-guardng.service";

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((module) => module.DashboardModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then((module) => module.AccountModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contract',
        loadChildren: () => import('./pages/contract/contract.module').then((module) => module.ContractModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'company',
        loadChildren: () => import('./pages/company/company.module').then((module) => module.CompanyModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'notification',
        loadChildren: () => import('./pages/notification/notification.module').then((module) => module.NotificationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'parcel',
        loadChildren: () => import('./pages/parcel/parcel.module').then((module) => module.ParcelModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'setting',
        loadChildren: () => import('./pages/setting/setting.module').then((module) => module.SettingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'proposal',
        loadChildren: () => import('./pages/proposal/proposal.module').then((module) => module.ProposalModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: '',
        redirectTo: '/guest/login',
        pathMatch: 'full'
      },
      {
        path: 'guest',
        loadChildren: () => import('./pages/authentication/authentication.module').then((module) => module.AuthenticationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
