import axios from "axios";
import { AUTH_TOKEN } from "@/app/app.constant";
import { environment } from "@/environments/environment";

const API = axios.create({
  baseURL: environment.baseURL,
  headers: {
  Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
}
});

export default API;
