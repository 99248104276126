import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import moment from "moment";

interface Action {
  icon: string,
  bg: string,
  function?: Function,
  url?: string,
  fieldConditionShow?: string
}

interface Badges {
  bg: 'success' | 'primary' | 'danger' | 'info' | 'warning' | 'none',
  name: string,
  validation: any
}

interface Align {
  header: 'start' | 'center' | 'end'
  body?: 'start' | 'center' | 'end'
}

export interface ColumnsTable {
  type: 'text' | 'text-object' | 'text-style' | 'badge' | 'action' | 'date-formatted' | 'money'
  value: string,
  complement? : string
  style? : any
  field?: string,
  field2?: string,
  currency?: 'BRL' | 'USD',
  badges?: Badges[],
  actions?: Action[],
  align?: Align
  pattern?: string
}

export interface Paginator {
    page?: number,
    first: number,
    rows: number,
    pageCount?: number
}

@Component({
  selector: 'app-pg-table',
  templateUrl: './pg-table.component.html',
  styleUrls: ['./pg-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PgTableComponent {
  @Input() dadosTabela: any[]
  @Input() first?: number = 0
  @Input() rows?: number = 10
  @Input() totalRecords: number
  @Input() loading?: boolean = false
  @Input() paginator?: boolean = false
  @Input() sort?: boolean = false
  @Input() columns: ColumnsTable[]
  @Input() getData!: Function
  @Input() striped: 'p-datatable-striped' | 'p-datatable-gridlines' | string = ''

  @Input() fontHeader?: string = '14px'
  @Input() fontBody?: string = '14px'

  @Output() updatePage = new EventEmitter<Paginator>();


  disable: boolean = false;
  constructor(
    private router: Router
  ){}

  async buscarPage(event) {
    this.updatePage.emit(event)
  }

  execute(func, url, value){
    if (func != undefined){
      func(value)
    }else {

      this.router.navigateByUrl(`${url}/${value.id}`)
    }
  }

  protected readonly moment = moment;

  styleConcatentation(valueOne: any, valueTwo ): any {
    return {
      ...valueOne, ...valueTwo
    }
  }
}
