import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParcelService {

  constructor() { }

  getParcels(){
    // const response = await API.get( `/parcels?limit=${data.length}&offset=${data.start}`)
    return {
      "parcelas": [
          {
              "idProposta": 4492707,
              "dataOperacao": "2023-0",
              "nome": "César de Souza Lima",
              "cpf": "07576736860",
              "matricula": "99999999",
              "numeroParcela": 3,
              "status": "ABERTO",
              "vencimento": "2023-05-18",
              "valor": 500.00,
              "valor_pago": 500.00,
              "mora": 0.00,
              "multa": 0.00,
              "juro": 0.00,
              "cnab_contrato": "xyz1.cnab",
              "cnab_cobranca": "",
              "cnab_cancelamento": ""
          },
          {
            "idProposta": 4492720,
            "dataOperacao": "2023-0",
            "nome": "Carlos Silva",
            "cpf": "6356736860",
            "matricula": "99999999",
            "numeroParcela": 4,
            "status": "CANCELADO",
            "vencimento": "2023-05-18",
            "valor": 500.00,
            "valor_pago": 500.00,
            "mora": 0.00,
            "multa": 0.00,
            "juro": 0.00,
            "cnab_contrato": "xyz1.cnab",
            "cnab_cobranca": "",
            "cnab_cancelamento": ""
        },
        {
          "idProposta": 4492740,
          "dataOperacao": "2023-0",
          "nome": "Maria jose",
          "cpf": "89576736860",
          "matricula": "99999999",
          "numeroParcela": 5,
          "status": "PENDENTE",
          "vencimento": "2023-05-18",
          "valor": 500.00,
          "valor_pago": 500.00,
          "mora": 0.00,
          "multa": 0.00,
          "juro": 0.00,
          "cnab_contrato": "xyz1.cnab",
          "cnab_cobranca": "",
          "cnab_cancelamento": ""
      }
  
      ],
      "total": 1,
      "pagina": 1,
      "quantidadePorPagina": 3
  }
  }
}
